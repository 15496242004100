import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/NavButtons';
import PrimaryBox from 'components/Motion_Detection/Passive_Infrared_Detection/PrimaryBox';
import ForumBox from 'components/Motion_Detection/FTP_Server_Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Passive Infrared Motion Detection",
  "path": "/Motion_Detection/Passive_Infrared_Detection/",
  "dateChanged": "2021-06-30",
  "author": "Mike Polinowski",
  "excerpt": "Use your camera's PIR Sensor to minimize the risks of false alerts by light changes.",
  "image": "./MD_SearchThumb_PIR_Sensor.png",
  "social": "/images/Search/MD_SearchThumb_PIR_Sensor.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Motion-Detection-Setup_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Passive Infrared Motion Detection' dateChanged='2021-06-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use your camera`s PIR Sensor to minimize the risks of false alerts by light changes. The PIR body heat detector can be coupled with the regular software based motion detection.' image='/images/Search/MD_SearchThumb_PIR_Sensor.png' twitter='/images/Search/MD_SearchThumb_PIR_Sensor.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Passive_Infrared_Detection/' locationFR='/fr/Motion_Detection/Passive_Infrared_Detection/' crumbLabel="PIR Sensor" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "passive-infrared-sensors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#passive-infrared-sensors",
        "aria-label": "passive infrared sensors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Passive Infrared Sensors`}</h1>
    <h2 {...{
      "id": "body-heat-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#body-heat-detection",
        "aria-label": "body heat detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Body Heat Detection`}</h2>
    <p>{`Most INSTAR cameras now come with an pre-installed passive infrared (`}<strong parentName="p">{`PIR`}</strong>{`) sensor. PIR sensors detect changes in temperature in window of surveillance in front your camera. This can be the heat that is emitted by your body, or the hot engine bonnet of your car, in form of IR radiation. This radiation has wavelengths between `}<strong parentName="p">{`1 µm`}</strong>{` to `}<strong parentName="p">{`1,5 mm`}</strong>{` with a peak of emission around `}<strong parentName="p">{`10 µm`}</strong>{` and is  invisible to the human eye.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/79651e30098d1a00f254d5ae282c6f54/29007/How-a-PIR-works-01_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlElEQVQoz3WSQZKbMBBF53a5QS4yh8g2i5wg29llN7lCKh5PxfaMwBgQAgQIIQFmgLL9U2qXY1e50psWavH0+6sfTqcTXLhsmgZ1rSg3WsMYA2sa1ErBWkN7TaP/1auyPP+jFI7HI3EeLsC+6+Cxd3Rti2EY8DEMtA7DEKvNGq21GMcR+/2e6q6WCkF7eZZimqYr8AIVnEMkCVRVIU9TMM9HvFnC//mE7S7C1mOkxilzEJ8xOhsGW8y3QBfTOGK5WMAaQyp4uIOeZpQ/viJ+/IQBR2RcgEcR2rYlxTyO6axTeg+cJiwXv2HM2UceBFDzDPb8BeG3z2BWotcGUuYIgwBlIakj17pI+D3Q+cLeNtj3PQopUasS4weQ6WfE6jtWaw9xuMM8z+S3g3rvb/Qts+w/HiYceZbB9xhSkaDTFhu1xq9qhd50KAqJsijIN3fp68sCW99D4PtX4GVkXOi6PptelbTurEHd9kiVJuXXUTqP0J/XJT2ie6S7sbnk23AzlgmBJIog85wuuQ3X7uFwINUuO+BfXlGnU0t+LSoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79651e30098d1a00f254d5ae282c6f54/e4706/How-a-PIR-works-01_EN.avif 230w", "/en/static/79651e30098d1a00f254d5ae282c6f54/d1af7/How-a-PIR-works-01_EN.avif 460w", "/en/static/79651e30098d1a00f254d5ae282c6f54/7f308/How-a-PIR-works-01_EN.avif 920w", "/en/static/79651e30098d1a00f254d5ae282c6f54/e1c99/How-a-PIR-works-01_EN.avif 1380w", "/en/static/79651e30098d1a00f254d5ae282c6f54/2babf/How-a-PIR-works-01_EN.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/79651e30098d1a00f254d5ae282c6f54/a0b58/How-a-PIR-works-01_EN.webp 230w", "/en/static/79651e30098d1a00f254d5ae282c6f54/bc10c/How-a-PIR-works-01_EN.webp 460w", "/en/static/79651e30098d1a00f254d5ae282c6f54/966d8/How-a-PIR-works-01_EN.webp 920w", "/en/static/79651e30098d1a00f254d5ae282c6f54/445df/How-a-PIR-works-01_EN.webp 1380w", "/en/static/79651e30098d1a00f254d5ae282c6f54/fad48/How-a-PIR-works-01_EN.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79651e30098d1a00f254d5ae282c6f54/81c8e/How-a-PIR-works-01_EN.png 230w", "/en/static/79651e30098d1a00f254d5ae282c6f54/08a84/How-a-PIR-works-01_EN.png 460w", "/en/static/79651e30098d1a00f254d5ae282c6f54/c0255/How-a-PIR-works-01_EN.png 920w", "/en/static/79651e30098d1a00f254d5ae282c6f54/b1001/How-a-PIR-works-01_EN.png 1380w", "/en/static/79651e30098d1a00f254d5ae282c6f54/29007/How-a-PIR-works-01_EN.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/79651e30098d1a00f254d5ae282c6f54/c0255/How-a-PIR-works-01_EN.png",
              "alt": "Passive Infrared Motion Detection",
              "title": "Passive Infrared Motion Detection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p>{`Our indoor cameras (e.g. `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/"
      }}>{`IN-8015 FHD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8003_HD/"
      }}>{`IN-8003 FHD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8001_HD/"
      }}>{`IN-8001 FHD`}</a>{`) use a small PIR sensor with an `}<strong parentName="p">{`horizontal angle of view of 120°`}</strong>{` and a `}<strong parentName="p">{`range of approx. 5 m`}</strong>{`. The outdoor cameras (e.g. `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9020_HD/"
      }}>{`IN-9020 FHD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9008_HD/"
      }}>{`IN-9008 FHD`}</a>{`) use a more powerful `}<strong parentName="p">{`Panasonic PIR sensor`}</strong>{` that also has an `}<strong parentName="p">{`horizontal angle of view of 120°`}</strong>{`, but a `}<strong parentName="p">{`range of approx. 12 m`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/29007/How-a-PIR-works-02_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABvElEQVQoz2VSW27bMBD0vXuEnqHoCfrVFMhfk9qWrMQoCrT9SB1bqqu3ZIsiJZGURE5AOlZthMCCAJc7uzOzM631AABKKZ2lKfzdFlEYYhgG9L3EOI44H6UUzJ80ScAoPT1q/Xqd7tkZkNa1jqMIQggILkAIAWMNxlFNBSZ410FwjqqqbFPTRF+AToC9lLosCpugjGHlPeD5eXvVXSsFqRRoJ+C6HsIwtIAmrgCHYQTnXJOqQtt2eHhcY7F0sHRcMMamz6bsGGwR/3AQbNbwg72d9JLBDMAQ/I2Rprk22jyuv+Pufm7B7ucLFEV5KhhHmDnH32s0tx+Q+jcgpAWl9aTxBOj7EZIktxo6Kw/zxRKOu7JRluV/2q/UjU1NHkCr8QpsMsUIL4TQhFSglMFdeZbyYulaYy4ptYxBdC14r9D0Azour4ybTJFS6CLPbeJ4rCzlpz8bXJ6+7/FvvwdrGpA0At89gRYbCM7eApoJjcumyKxDWR5Q19Tu4ZlOLyVuv9zg67c5vM+f8PP9O/y6+4iOZW8B27bRx8MBjelOKrRNg5oQq6EUYlrsPMuQxBHiJEGQZAh2OxyKfNLwBVCC+cwn8eV1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/e4706/How-a-PIR-works-02_EN.avif 230w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/d1af7/How-a-PIR-works-02_EN.avif 460w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/7f308/How-a-PIR-works-02_EN.avif 920w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/e1c99/How-a-PIR-works-02_EN.avif 1380w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/2babf/How-a-PIR-works-02_EN.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/a0b58/How-a-PIR-works-02_EN.webp 230w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/bc10c/How-a-PIR-works-02_EN.webp 460w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/966d8/How-a-PIR-works-02_EN.webp 920w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/445df/How-a-PIR-works-02_EN.webp 1380w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/fad48/How-a-PIR-works-02_EN.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/81c8e/How-a-PIR-works-02_EN.png 230w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/08a84/How-a-PIR-works-02_EN.png 460w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/c0255/How-a-PIR-works-02_EN.png 920w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/b1001/How-a-PIR-works-02_EN.png 1380w", "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/29007/How-a-PIR-works-02_EN.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7430b1a9a73e04d2a7e2f6f1dd441732/c0255/How-a-PIR-works-02_EN.png",
              "alt": "Passive Infrared Motion Detection",
              "title": "Passive Infrared Motion Detection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Passive infrared sensor see the moving heat signal through a so called Fresnel lense that divides the field of view of the sensor into small windows - depicted as rectangular beams in the sketch above. A simple PIR sensor will trigger an alert once it "sees" a hot object leave one window and enters a neighbouring window. More sophisticated sensors apply small programs that allow them to rule out background "noise" as alarm triggers`}</p>
    <blockquote>
      <p parentName="blockquote">{`The sensor is always more sensitive when they are installed in a way that you are forced to walk past them (upper graphic on the right) instead of walking toward them (lower graphic on the right).`}</p>
    </blockquote>
    <p>{`Always install your sensors not in front of an entry by at a 90° angle to the side of it. This forces you to walk through all detection windows. Making sure that you definitely reach the trigger threshold for the alarm detection. It is also recommended to install your sensor close to the ceiling as the installed Fresnel lenses are designed to "look" down. The installation close to and especially above a heat source can cause false alerts and has to be avoided.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Keep in mind that the world that your PIR sensor sees looks very different from what your eyes see. There are many potential downfalls with installing a PIR sensor in the wrong place or needing it to operate at the wrong time of the day.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/29007/How-a-PIR-works-03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACs0lEQVQoz02PS09TCQCF738wma0b/4Or2U8yKxNduJlkTMxo4nMGGchgRxEthWkCdij0NrbGUYFLX2JRoK9723tvX7SlpUofFPqwHRglKsiIieYz4saTfDlfzu4IuXKdYDTOxMDvuLx+5mI5fKEEfjlNKLlCJF0klpRZb7VZb2/yrNZASa8wJ6d5Gl0imnlGqlhFS2XQNBVh/+MnZseNXD76HZpf4s3/H1hvdVhrtml1tnho/Y0/Th1he+cNW693aP67RbXRotp8QbG6gX8+gMN5lxGLBdPwEMLe9ib6WB//nPmRejLEt9nfecnDnuN0fX+IiGPg6/Z+j923rw/83e4O57suc677EqdP/czJE8cRKmqQecMv3DlzDNluplGr0Sg/Z7NRp5LT8Io3GPvpMLaLP/Ci2aGytnZAs92hUqtxrPtXLliGGP37Nk6nE6GqR0jZB0lM2ijLTyjls1TyWeql56wup4knVUrFKKX6BqnCKvFsHkVP4PX5WAiFOX3+LFbRxti4jUGTCWHv/Qe2d/fZaLbpbL2iVKuTyhXQ01kCikYhl6FVLZPUNaY8jxmxT2K87WBodIIHkod+w1WGB41EolEezXoRHPenuW6yYLhpxmwZ5869B3g8biRpmhmPFyWq4JfuM+Ww4ZMmEUWRQCjMRuc/GpsvCcsRAsFFllcKFFbyCBN2OxarFVG0I81ILCU04oEn+GYm8bhn8LhdWIdv8ee1fnq6r9DbdQm7w8FSsUSmWCai6mQKXx6lCSkKQjimIU1PMT5qZsppw3yjj2FDDyPGfm4aernW18NfJiMTooj78RxBNYWSyqNlvjIXUggoKhFNZyGiICyvVliMxnH5F5DcHpwOO4beKxgHriO5XLh8s4RjKrKeYFHRCapJlGQWdSl/0Auyhj8osyjHmA8rfAb1AHNcFWrv1gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c72d13d22982b4ae107a6a1e33cf8d32/e4706/How-a-PIR-works-03.avif 230w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/d1af7/How-a-PIR-works-03.avif 460w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/7f308/How-a-PIR-works-03.avif 920w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/e1c99/How-a-PIR-works-03.avif 1380w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/2babf/How-a-PIR-works-03.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c72d13d22982b4ae107a6a1e33cf8d32/a0b58/How-a-PIR-works-03.webp 230w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/bc10c/How-a-PIR-works-03.webp 460w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/966d8/How-a-PIR-works-03.webp 920w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/445df/How-a-PIR-works-03.webp 1380w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/fad48/How-a-PIR-works-03.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c72d13d22982b4ae107a6a1e33cf8d32/81c8e/How-a-PIR-works-03.png 230w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/08a84/How-a-PIR-works-03.png 460w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/c0255/How-a-PIR-works-03.png 920w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/b1001/How-a-PIR-works-03.png 1380w", "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/29007/How-a-PIR-works-03.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c72d13d22982b4ae107a6a1e33cf8d32/c0255/How-a-PIR-works-03.png",
              "alt": "Passive Infrared Motion Detection",
              "title": "Passive Infrared Motion Detection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The safest way to operate your INSTAR camera is to couple both the internal, software-based motion detection and the PIR motion sensor. By activating the sensor coupling in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Alarm/Actions menu`}</a>{` an alarm is only triggered when both detection methods agree that there is something going on that is worth reporting.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b1acaa69c386d19d88dc1b94a260732/f2364/How-a-PIR-works-04_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACCklEQVQoz0WR3UuTURzHn38isDcMCXVtbWqjtmFNY04TIQm8Uah/IbbnnOcldEWjFyOdW9LNMoWIbrqpu+omirA9hsvZosCLzFrm29b27JzznGfnFzOk7/2H74fvV8IYq7vRFKTFk6OPsvrcQjSdwTOGPmdE0kY0bUTSGflBJvb4o3YjpeLo8PCI2+32+XwSwoqMFU3XEZLvTiRMS5QIB4AP38jMmy1i1UxLcICSSbf/kOvxeFf36b6+3p6e0ODgeUlGu7CmIYTGb9+qcWYxCgCrv8uvcz9BcJtbAEBIlVTNazG9zXGk0+s81e7oDYckrKhYUXVdl2V5cnKCc04ZA4DlNfPJ/AZntEpITQjKqMX56BWl+fA+r+toc2PD2e4uSVG1KFL+wYmpBKW0UjEBYLtkrqxt2EIQQmzbJpRRZisq3n/oYGNTU8OBhnA4JNWX2mtOpZJCCGbVPTfL7HP+u1UoEKs+wY+NyruvpcuRqLvdc9Lnc3lcwWBAkvF/7XvTKULITrEIwF/lSyids9d/cZsDCMJomdGxq7HO4JlzAwP+gP+Et0NCWEV7cDI5BQA1IQBgZZM/XyrX/auwxYAX1mGneHP8Tri//8LQ0HG3s8XRIsn1q1RV0xRFGYuNZbOLxkLGMN4v5xa/5Jfyn3JPX8zPPnv7cvp+ZvbhpYsjDqfDH/C3Hmtt6/D8BeTxVisHDuM4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b1acaa69c386d19d88dc1b94a260732/e4706/How-a-PIR-works-04_EN.avif 230w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/d1af7/How-a-PIR-works-04_EN.avif 460w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/7f308/How-a-PIR-works-04_EN.avif 920w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/e1c99/How-a-PIR-works-04_EN.avif 1380w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/b1526/How-a-PIR-works-04_EN.avif 1389w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b1acaa69c386d19d88dc1b94a260732/a0b58/How-a-PIR-works-04_EN.webp 230w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/bc10c/How-a-PIR-works-04_EN.webp 460w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/966d8/How-a-PIR-works-04_EN.webp 920w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/445df/How-a-PIR-works-04_EN.webp 1380w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/8e2cd/How-a-PIR-works-04_EN.webp 1389w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b1acaa69c386d19d88dc1b94a260732/81c8e/How-a-PIR-works-04_EN.png 230w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/08a84/How-a-PIR-works-04_EN.png 460w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/c0255/How-a-PIR-works-04_EN.png 920w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/b1001/How-a-PIR-works-04_EN.png 1380w", "/en/static/8b1acaa69c386d19d88dc1b94a260732/f2364/How-a-PIR-works-04_EN.png 1389w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b1acaa69c386d19d88dc1b94a260732/c0255/How-a-PIR-works-04_EN.png",
              "alt": "Passive Infrared Motion Detection",
              "title": "Passive Infrared Motion Detection",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      